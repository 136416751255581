<template>
    <div class="flex justify-between">
        <div class="w-[30%] text-[13px]">
            <div v-if="fcName" class="text-[#A4A6B3] font-semibold mb-[18px]">ФА "{{ fcName }}"</div>
            <div class="text-[#A4A6B3] font-semibold mb-[18px] w-[70%]">{{ fcAddress }}</div>
            <div class="text-[#A4A6B3] font-semibold mb-[30px]">
                <div v-if="firstPhone">+380{{ firstPhone }}</div>
                <div v-if="secondPhone">+380{{ secondPhone }}</div>
            </div>
            <div class="flex justify-between w-[80%]">
                <div v-if="webPage" class="cursor-pointer">
                    <a :href="webPage">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.91699 12.0827L12.0837 7.91602" stroke="#A4A6B3" stroke-linecap="round"/>
                            <path d="M14.0386 12.1746L16.2132 10C17.9289 8.28427 17.9289 5.50252 16.2132 3.7868C14.4975 2.07107 11.7157 2.07107 10 3.7868L7.82538 5.96142M12.1746 14.0386L10 16.2132C8.28427 17.9289 5.50252 17.9289 3.7868 16.2132C2.07107 14.4975 2.07107 11.7157 3.7868 10L5.96142 7.82538" stroke="#A4A6B3" stroke-linecap="round"/>
                        </svg>
                    </a>
                </div>
                <div v-if="instagramPage" class="cursor-pointer">
                    <a :href="instagramPage">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.08301 9.9987C2.08301 6.26675 2.08301 4.40077 3.24238 3.2414C4.40175 2.08203 6.26772 2.08203 9.99967 2.08203C13.7316 2.08203 15.5976 2.08203 16.757 3.2414C17.9163 4.40077 17.9163 6.26675 17.9163 9.9987C17.9163 13.7306 17.9163 15.5966 16.757 16.756C15.5976 17.9154 13.7316 17.9154 9.99967 17.9154C6.26772 17.9154 4.40175 17.9154 3.24238 16.756C2.08301 15.5966 2.08301 13.7306 2.08301 9.9987Z" stroke="#A4A6B3" stroke-linejoin="round"/>
                            <path d="M13.75 10C13.75 12.0711 12.0711 13.75 10 13.75C7.92893 13.75 6.25 12.0711 6.25 10C6.25 7.92893 7.92893 6.25 10 6.25C12.0711 6.25 13.75 7.92893 13.75 10Z" stroke="#A4A6B3"/>
                            <path d="M14.5898 5.41602L14.5824 5.41602" stroke="#A4A6B3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                </div>
                <div v-if="facebookPage" class="cursor-pointer">
                    <a :href="facebookPage">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.15184 8.61241C4.33704 8.61241 4.16699 8.77229 4.16699 9.53834V10.9272C4.16699 11.6933 4.33704 11.8532 5.15184 11.8532H7.12154V17.4087C7.12154 18.1748 7.29159 18.3346 8.10639 18.3346H10.0761C10.8909 18.3346 11.0609 18.1748 11.0609 17.4087V11.8532H13.2726C13.8906 11.8532 14.0498 11.7402 14.2196 11.1816L14.6416 9.79271C14.9324 8.83577 14.7533 8.61241 13.6947 8.61241H11.0609V6.2976C11.0609 5.78622 11.5019 5.37167 12.0458 5.37167H14.8488C15.6636 5.37167 15.8337 5.2118 15.8337 4.44575V2.59389C15.8337 1.82785 15.6636 1.66797 14.8488 1.66797H12.0458C9.3262 1.66797 7.12154 3.74072 7.12154 6.2976V8.61241H5.15184Z" stroke="#A4A6B3" stroke-linejoin="round"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>

        <div class="w-[40%] flex flex-col relative bottom-[30px] items-center">

            <div>
                <img src="@/assets/logo.svg" class="w-[100px] h-[130px]">
            </div>

            <div class="text-[#A4A6B3] text-center text-[13px]">
                <div class="font-light">SINCE</div>
                <div v-if="sinceDate" class="font-semibold">{{ sinceDate.split('-').reverse().join('.') }}</div>
                <div v-else class="font-semibold">--.--.----</div>
            </div>
            
        </div>
        
        <div class="w-[30%] text-[#A4A6B3] text-[13px]">

            <div class="ml-auto w-max font-semibold">
                <div class="mb-[20px]">{{ bankName }}</div>
                <div class="mb-[9px]">{{ bankAccount }}</div>
                <div>

                    <table class="w-full">
                        <tbody>
                            <tr v-if="receiverId">
                                <td>Отримувач</td>
                                <td>{{ receiverId }}</td>
                            </tr>
                            <tr v-if="ERDPOUcode">
                                <td>ЄДРПОУ</td>
                                <td>{{ ERDPOUcode }}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

        </div>

    </div>   
</template>

<script>
    export default {
        name: 'Header',

        props: {
            fcName: {
                type: String,
                default: ''
            },
            fcAddress: {
                type: String,
                default: ''
            },
            firstPhone: {
                type: String,
                default: ''
            },
            secondPhone: {
                type: String,
                default: ''
            },

            webPage: {
                type: String,
                default: ''
            },
            facebookPage: {
                type: String,
                default: ''
            },
            instagramPage: {
                type: String,
                default: ''
            },

            bankName: {
                type: String,
                default: ''
            },
            bankAccount: {
                type: String,
                default: ''
            },
            receiverId: {
                type: String,
                default: ''
            },
            ERDPOUcode: {
                type: String,
                default: ''
            },

            sinceDate: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>